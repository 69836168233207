<template>
  <div class="fragment">
    <div v-if="$store.getters.getImportOrdersCommonList.total" class="pagination-wrap mt-3">
      <PerPageSelect
          :currentPerPage="$store.getters.getImportOrdersForPage"
          :cookieName="'perPageImportOrders'"
          @changePerPage="(perPage) => {$emit('changePerPage', perPage)}"
      />
    </div>

    <template v-if="$store.getters.getImportOrders.length > 0 && $store.getters.getImportOrdersLoading !== true">
      <div class="site-table-wrap import-orders-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(tableFieldsTranslations)"></span>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>{{ $t('importO_order.localization_value.value') }}</th>
            <th>{{ $t('importO_created.localization_value.value') }}</th>
            <th v-if="isAdmin">{{ $t('importO_user.localization_value.value') }}</th>
            <th v-if="!checkOpenCart && !checkWooCommerce && !checkExpressPoshta">{{ $t('importO_payPalTransaction.localization_value.value') }}</th>
            <th>{{ $t('importO_shopType.localization_value.value') }}</th>
            <th>{{ $t('importO_customer.localization_value.value') }}</th>
            <th v-if="checkExpressPoshta">{{ $t('importO_order.localization_value.value') }} FBM</th>
            <th v-if="!isAdmin">{{ $t('importO_eMail.localization_value.value') }}</th>
            <th>{{ $t('importO_trackingNumber.localization_value.value') }}</th>
            <th v-if="checkExpressPoshta">{{ $t('importO_orderType.localization_value.value') }}</th>
            <th>{{ $t('importO_itemNumber.localization_value.value') }}</th>
            <th  v-if="!isAdmin">{{ $t('importO_shopName.localization_value.value') }}</th>
            <th>{{ $t('importO_shipped.localization_value.value') }}</th>
            <th  v-if="isAdmin">{{ $t('importO_total.localization_value.value') }}</th>
            <th>{{ $t('importO_manage.localization_value.value') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in $store.getters.getImportOrders" :key="index">
            <td>
              <DefaultCheckbox class="empty-label" :selectedNow="selectedNow" :dataValue="item.id"/>
            </td>
            <td><span class="table-link btn-style" @click="showOrderInfo(item.id)">#{{item.id}}</span></td>
            <td>{{ item.original_created_at | moment("DD MMM, YYYY") }}</td>
            <td v-if="isAdmin">
              <TableUserColumn
                      :item="item"
              />
            </td>
<!--            <td>{{item.transaction_number !== null ? item.transaction_number : ''}}</td>-->
            <td v-if="!checkOpenCart && !checkWooCommerce && !checkExpressPoshta">
<!--              {{item.payment_transaction !== null ? item.payment_transaction.transaction_id : ''}}-->

              <template v-if="item.payment_transaction !== null">
                <TooltipTextHelper
                    :fullTextWidth="110"
                    :text="item.payment_transaction.transaction_id"
                    :paragraph="item.payment_transaction.transaction_id"
                />
              </template>

            </td>
            <td>{{item.shop_type !== null ? item.shop_type.currentTranslate.label : ''}}</td>
            <td>{{item.original_first_name}} {{item.original_last_name}}</td>
            <td v-if="checkExpressPoshta">
              <router-link
                  class="table-link btn-style"
                  v-if="item.order_fbm_attachment && item.order_fbm_attachment.id"
                  :to="$store.getters.GET_PATHS.ordersFBM + '/show/' + getOrderFBMTypeById(item.order_fbm_attachment.order_type_id).name + '/' + item.order_fbm_attachment.id">
                {{item.order_fbm_attachment.id}}
              </router-link>
              <template v-else>
                -
              </template>
            </td>
            <td v-if="!isAdmin">
              <div class="transactions-row">
                <TooltipTextHelper
                    :fullTextWidth="110"
                    :text="item.original_email"
                    :paragraph="item.original_email"
                />
              </div>
            </td>
            <td>
              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate(['importO_trackingNumber', 'importO_addNumber'])"></span>

                <template v-if="item.tracking_number !== null && item.tracking_number !== ''">
                  <!--<span>{{item.tracking_number}}</span>-->
                  <span v-bind:class="{'table-link btn-style': item.tracking_sent === 0}">
                     <TooltipTextHelper
                         :fullTextWidth="110"
                         :text="item.tracking_number"
                         :paragraph="item.tracking_number"
                     />
                  </span>
                  <div class="table-ico">
                    <v-popover
                            v-if="item.tracking_sent === 0"
                            class="site-tooltip"
                            offset="5"
                            placement="top-left"
                            @show="hideEditField = true"
                            @hide="hideEditField = false"
                    >
                    <span class="site-link">
                        <LinkButton
                                :type="'edit'"
                        />
                    </span>
                      <template slot="popover">
                        <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}
                        </div>
                        <InputEditField
                                :value="item.tracking_number"
                                :propId="item.id"
                                :hideEditField="hideEditField"
                                @onChange="changeTrackingNumberImportOrders"
                        />
                        <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                      </template>
                    </v-popover>
                  </div>
                </template>
                <template v-else>
                  <div class="">
                    <v-popover
                            class="site-tooltip"
                            offset="5"
                            placement="top-left"
                            @show="hideEditField = true"
                            @hide="hideEditField = false"
                    >
                      <span class="table-link btn-style">{{ $t('importO_addNumber.localization_value.value') }}</span>

                      <template slot="popover">
                        <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}
                        </div>
                        <InputEditField
                                :value="''"
                                :propId="item.id"
                                :hideEditField="hideEditField"
                                @onChange="changeTrackingNumberImportOrders"
                        />

                        <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                      </template>
                    </v-popover>
                  </div>
                </template>
              </div>
            </td>
            <td v-if="checkExpressPoshta">
              {{item.order_type}}
            </td>
            <td>
              <TooltipTextHelper
                  :fullTextWidth="120"
                  :text="item.item_number"
                  :paragraph="item.item_number"
              />
<!--              <v-popover-->
<!--                      class="site-tooltip"-->
<!--                      offset="5"-->
<!--                      placement="top"-->
<!--                      trigger="hover"-->
<!--              >-->
<!--                <div class="ellipsis">{{item.item_number}}</div>-->
<!--                <template slot="popover">-->
<!--                  <p>-->
<!--                    {{item.item_number}}-->
<!--                  </p>-->
<!--                </template>-->
<!--              </v-popover>-->
            </td>
            <td v-if="!isAdmin">
              <div class="ellipsis">
                <!--<ValueHelper-->
                <!--v-if="item.shop"-->
                <!--:replaceValue="''"-->
                <!--:value="item.shop.shop_name"-->
                <!--/>-->
                <TooltipTextHelper
                    v-if="item.shop"
                    :fullTextWidth="110"
                    :text="item.shop.shop_name"
                    :paragraph="item.shop.shop_name"
                />
              </div>
            </td>
            <td>
              <template v-if="item.shipped_at !== null">
                {{item.shipped_at | moment("DD MMM, YYYY")}}
              </template>
              <template v-else>
                -
              </template>
            </td>
            <td v-if="isAdmin">{{Number(item.original_total)}}</td>
            <td>
              <div class="table-right" v-if="isAdmin">
                <div class="table-right__item">
                  <LinkButton
                          :type="'delete'"
                          @click.native="removeOrder(item.id)"
                  />
                </div>

                <ManageOrdersInTable
                        :item="item"
                        :typeTransaction="'order'"
                        :newOrderLink="true"
                        @showWhatOrderCreatePopup="showWhatOrderCreatePopup"
                />
              </div>

              <ManageOrdersInTable
                  v-else
                  :item="item"
                  :typeTransaction="'order'"
                  @showChooseExpressOrder="showChooseExpressOrder"
                  @showChooseOrderFBMCreatePopup="showChooseOrderFBMCreatePopup"
                  @showChooseEasyOrderCreatePopup="showChooseEasyOrderCreatePopup"
              />

            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card"  v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item" v-for="(item, index) in $store.getters.getImportOrders" :key="index">
<!--                  v-if="isAdmin"-->
              <ImportOrdersMobileTable
                      :item="item"
                      :typeShops="typeShops"
                      @showWhatOrderCreatePopup="showWhatOrderCreatePopup"
                      @showChooseOrderFBMCreatePopup="showChooseOrderFBMCreatePopup"
                      @showChooseExpressOrder="showChooseExpressOrder"
                      @changeTrackingNumberImportOrders="changeTrackingNumberImportOrders"
                      @removeOrder="removeOrder(item.id)"
                      @showOrderInfo="showOrderInfo(item.id)"
                      :selectedNow="selectedNow"
              />

<!--              <ImportOrdersUserMobileTable-->
<!--                  v-else-->
<!--                  :item="item"-->
<!--                  :selectedNow="selectedNow"-->
<!--                  :typeShops="typeShops"-->
<!--                  @showChooseOrderFBMCreatePopup="showChooseOrderFBMCreatePopup"-->
<!--                  @showChooseExpressOrder="showChooseExpressOrder"-->
<!--                  @changeTrackingNumberImportOrders="changeTrackingNumberImportOrders"-->
<!--              />-->
            </div>
          </div>
        </div>
        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getImportOrders.length > 0 && $store.getters.getImportOrdersCommonList.next_page_url !== null"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextImportOrdersPage}"
                    :count="$store.getters.getImportOrdersCommonList.total - $store.getters.getImportOrdersForPage * $store.getters.getImportOrdersCommonList.current_page < $store.getters.getImportOrdersForPage ?
                      $store.getters.getImportOrdersCommonList.total - $store.getters.getImportOrdersForPage * $store.getters.getImportOrdersCommonList.current_page:
                      $store.getters.getImportOrdersForPage"
            />

            <ExportBtn
                @downloadFiles="type => $emit('downloadFiles', type)"
                class="table-bottom-btn__right"/>
          </div>
        </div>
      </div>
    </template>
    <template v-else>

      <template v-if="$store.getters.getImportOrdersLoading === false && $store.getters.getImportOrders.length === 0">
        <div class="empty-list "
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
             'no-data' : countFilterParams > 0}">
          <span class="admin-edit"
                @click="editTranslate(['common_noResult', 'common_noDataYet', 'common_noResultSeems', 'common_noDataYetTxt', 'common_resetFilters'])"></span>

          <div class="empty-list__title" v-if="countFilterParams > 0">
            {{$t('common_noResult.localization_value.value')}}
          </div>
          <div class="empty-list__title" v-else>
            {{$t('common_noDataYet.localization_value.value')}}
          </div>

          <div class="empty-list__txt" v-if="countFilterParams > 0"
               v-html="$t('common_noResultSeems.localization_value.value')">
          </div>
          <div class="empty-list__txt" v-else v-html="$t('common_noDataYetTxt.localization_value.value')">
          </div>

          <div class="empty-list__btn" v-if="countFilterParams > 0">
            <MainButton
                    class="secondary btn-fit-content"
                    :value="$t('common_resetFilters.localization_value.value')"
                    @click.native="$emit('resetFilter')"
            />
          </div>
        </div>
      </template>
    </template>


    <AdminOrderCreatePopup
            @close="closeWhatOrderCreatePopup"
            v-if="isModalWhatOrderCreatePopup"
            :dhl="expressLinks.dhl"
            :fedex="expressLinks.fedex"
            :tnt="expressLinks.tnt"

            :consolidation="fbmLinks.consolidation"
            :warehouse="fbmLinks.warehouse"
            :label="fbmLinks.label"
    />

    <CommentsPopup
            v-if="isModalCommentsPopup"
            @close="changeCommentsPopup(false)"
    />


    <ChooseOrderFBMCreatePopup
        @close="closeChooseOrderFBMCreatePopup"
        v-if="isModalChooseOrderFBMCreatePopup"
        :consolidation="_.has(this.currentPermissions, PERMISSIONS.ORDER_FBM_CONSOLIDATION) ? orderFBMLinks.consolidation : null"
        :warehouse="_.has(currentPermissions, PERMISSIONS.ORDER_FBM_WAREHOUSE) ? orderFBMLinks.warehouse : null"
        :label="_.has(currentPermissions, PERMISSIONS.ORDER_FBM_LABEL) ? orderFBMLinks.label : null"
    />

    <ChooseExpressOrderPopup
        @close="closeChooseExpressOrder"
        v-if="isModalChooseExpressOrder"
        :dhl="_.has(this.currentPermissions, PERMISSIONS.ORDER_EXPRESS_DHL) ? expressLinks.dhl : null"
        :fedex="_.has(this.currentPermissions, PERMISSIONS.ORDER_EXPRESS_FEDEX) ? expressLinks.fedex : null"
        :tnt="_.has(this.currentPermissions, PERMISSIONS.ORDER_EXPRESS_TNT) ? expressLinks.tnt : null"
        :npg="null"
    />
<!--        :npg="_.has(this.currentPermissions, PERMISSIONS.ORDER_EXPRESS_NPG) ? expressLinks.npg : null"-->

    <ChooseEasyOrderCreatePopup
        v-if="isModalChooseEasyOrderCreate"
        :type="'order'"
        :id="orderId"
        @close="isModalChooseEasyOrderCreate = false"
    />

    <OrderInfo
            v-if="isModalOrderInfo"
            @close="closeOrderInfo(false)"
            :typeShops="typeShops"
    />

    <SendEmailPopup
            v-if="isModalSendEmailPopup"
            @close="changeSendEmailPopup(false)"
    />

    <LastFiveIPPopup
            v-if="isModalLastFiveIPPopup"
            @close="changeLastFiveIPPopup(false)"
    />

    <StatisticPopup
            v-if="isModalStatisticPopup"
            @close="changeStatisticPopup(false)"
    />
  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export';
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import InputEditField from "../../../../../../UI/inputs/InputEditField/InputEditField";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import ImportOrdersMobileTable from "./ImportOrdersMobileTable/ImportOrdersMobileTable";
  import AdminOrderCreatePopup from "../../../../popups/AdminOrderCreatePopup/AdminOrderCreatePopup";
  import CommentsPopup from "../../../../popups/CommentsPopup/CommentsPopup";
  import SendEmailPopup from "../../../../popups/SendEmailPopup/SendEmailPopup";
  import LastFiveIPPopup from "../../../../popups/LastFiveIPPopup/LastFiveIPPopup";
  import StatisticPopup from "../../../../popups/StatisticPopup/StatisticPopup";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import OrderInfo from "../../../../popups/OrderInfo/OrderInfo";
  import {trackingNumberFunc} from "../../../../../../../mixins/trackingNumberMixins/trackingNumberFunc";
  import ChooseExpressOrderPopup from "../../../../../../coreComponents/Popups/ChooseExpressOrder/ChooseExpressOrderPopup";
  import ManageOrdersInTable
    from "../../../../../../coreComponents/TableComponents/ManageOrdersInTable/ManageOrdersInTable";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
  import ChooseOrderFBMCreatePopup
    from "../../../../../../coreComponents/Popups/ChooseOrderFBMCreatePopup/ChooseOrderFBMCreatePopup";
  import PerPageSelect from "@/components/coreComponents/PerPageSelect/PerPageSelect";
  import {FBMMixinsHelper} from "@/mixins/FBMMixins/FBMMixins";
  import ChooseEasyOrderCreatePopup
    from "@/components/coreComponents/Popups/ChooseEasyOrderCreatePopup/ChooseEasyOrderCreatePopup";

  export default {
    name: "ImportOrdersTable",
    components: {
      ChooseEasyOrderCreatePopup,
      PerPageSelect,
      TooltipTextHelper,
      TableUserColumn,
      ManageOrdersInTable,
      ChooseExpressOrderPopup,
      ShowMore,
      ExportBtn,
      LinkButton,
      DefaultCheckbox,
      InputEditField,
      ChooseOrderFBMCreatePopup,
      AdminOrderCreatePopup,
      MainButton,
      CommentsPopup,
      SendEmailPopup,
      LastFiveIPPopup,
      StatisticPopup,
      ImportOrdersMobileTable,
      OrderInfo,
    },

    props: [
      'countFilterParams',
      'selectedNow',
      'typeShops',
    ],

    mixins: [mixinDetictingMobile, trackingNumberFunc, FBMMixinsHelper],

    computed: {
      checkOpenCart() {
        return this._.find(this.typeShops, {active: true})?.shop.name === 'opencart'
      },
      checkWooCommerce() {
        return this._.find(this.typeShops, {active: true})?.shop.name === 'woocommerce'
      },
      checkExpressPoshta() {
        return this._.find(this.typeShops, {active: true})?.shop.name === 'expressPoshtaShop'
      },
    },

    data() {
      return {
        order: {},
        expressLinks: {},

        orderFBMLinks: {
          consolidation: null,
          warehouse: null,
          label: null,
        },

        show1: false,
        show2: false,

        hideEditField: false,

        isModalChooseEasyOrderCreate: false,
        isModalChooseOrderFBMCreatePopup: false,
        isModalWhatOrderCreatePopup: false,
        isModalChooseExpressOrder: false,
        isModalOrderInfo: false,

        isModalCommentsPopup: false,
        isModalSendEmailPopup: false,
        isModalLastFiveIPPopup: false,
        isModalStatisticPopup: false,

        tableFieldsTranslations: [
          'importO_order',
          'importO_created',
          'importO_user',
          'importO_payPalTransaction',
          'importO_customer',
          'importO_eMail',
          'importO_trackingNumber',
          'importO_itemNumber',
          'importO_shopName',
          'importO_shipped',
          'importO_total',
          'importO_manage',
          'importO_addNumber',
          'importO_trackingNumber',
          'importO_orderType',
          'importO_newOrder',
          'importO_usualOrder',
          'importO_expressOrder',
        ],

        orderId: -1,
        fbmLinks: {},

      }
    },

    mounted() {

    },

    methods: {
      showChooseEasyOrderCreatePopup (item) {
        this.orderId = item.id
        this.isModalChooseEasyOrderCreate = true
      },

      showChooseOrderFBMCreatePopup (order, links) {
        this.orderFBMLinks = links
        this.order = order
        this.isModalChooseOrderFBMCreatePopup = true
      },

      closeChooseOrderFBMCreatePopup() {
        this.isModalChooseOrderFBMCreatePopup = false
      },

      showWhatOrderCreatePopup(order, links, linksFbm) {
        this.isModalWhatOrderCreatePopup = true
        this.expressLinks = links
        this.fbmLinks = linksFbm
        this.order = order
      },

      closeWhatOrderCreatePopup() {
        this.isModalWhatOrderCreatePopup = false
      },

      showOrderInfo(id) {
        this.$store.dispatch('getImportOrders', id).then(() => {
          this.isModalOrderInfo = true
        })
      },

      closeOrderInfo() {
        this.isModalOrderInfo = false
      },

      showChooseExpressOrder (order, links) {
        this.expressLinks = links
        this.order = order
        this.isModalChooseExpressOrder = true
      },

      closeChooseExpressOrder() {
        this.isModalChooseExpressOrder = false
      },



      removeOrder(id) {
        let text = {
          title: 'importO_deleteOrder',
          txt: 'importO_deleteOrderQuest',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {
          this.$store.dispatch('deleteImportOrders', id).then((response) => {

            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let orders = this.$store.getters.getImportOrders
              orders.map((item, index) => {
                if (item.id === id) {
                  orders.splice(index, 1)
                }
              })
              setTimeout(() => {
                this.openNotify('success', 'common_notificationStoreDeleted')
              }, 200)
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          }).catch(error => this.createErrorLog(error))

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      changeCommentsPopup(val) {
        this.isModalCommentsPopup = val
      },
      changeSendEmailPopup(val) {
        this.isModalSendEmailPopup = val
      },
      changeLastFiveIPPopup(val) {
        this.isModalLastFiveIPPopup = val
      },
      changeStatisticPopup(val) {
        this.isModalStatisticPopup = val
      },
    },
  }
</script>

<style scoped>
  .site-table thead th:first-child,
  .site-table tbody td:first-child {
    padding-right: 0;
  }

  .import-orders-table table td:last-child,
  .import-orders-table table th:last-child {
    width: 135px;
    padding-right: 15px;
  }

  @media (max-width: 1600px) {

  }

  .table-card__item-btn-i:first-child {
    padding-left: 5px;
  }

  .table-row-word-break {
    max-width: 160px;
    word-break: break-all;
  }

  .site-table .ellipsis {
    max-width: 170px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
  }

  .site-table tbody td{
    padding: 20px 8px;
  }

  .site-table thead th {
    padding: 20px 8px;
  }

  /deep/ .transactions-row .tooltip-helper-full{
    overflow: hidden;
    max-width: 55px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
